import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";

const vtf = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.red.darken1, // #E53935
        secondary: "#000000", // #FFCDD2
        accent: colors.red.base, // #3F51B5
      },
    },
  },
});
Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, {
  vuetify: vtf, // same as "vuetify: vuetify"
  iconsGroup: "mdi",
});

export default vtf;
