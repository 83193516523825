<script>
import messageBus from "../messageBus.js";
const icons = {
  success: "mdi-check",
  error: "mdi-alert-circle",
  search: "mdi-search",
};
export default {
  created() {
    messageBus.$on("onNewMessage", (message) => {
      this.addMessage(message);
    });
  },
  destroyed() {
    messageBus.$off("onNewMessage");
  },
  data() {
    return {
      messages: [],
      icons,
      alwaysOpen: true,
      timeout: 3000,
    };
  },
  methods: {
    addMessage(message) {
      this.messages.push(message);
      this.alwaysOpen = true;
      if (!message.permanent) {
        setTimeout(() => {
          this.messages.pop();
        }, this.timeout + 200);
      }
    },
    removeMessage(id) {
      this.messages.splice(id, 1);
    },
  },
};
</script>

<template>
  <div class="meassage-bar">
    <ul class="message-bar-fixed">
      <li
        class="message-item"
        :class="`message-item-${m.type}`"
        v-for="(m, $i) in messages"
        :key="$i"
      >
        <transition name="slide-fade">
          <div class="message-card" :class="`message-card-${m.type}`">
            <v-snackbar
              v-model="alwaysOpen"
              class="snacky"
              outlined
              :timeout="timeout"
              dark
            >
              {{ m.label }}

              <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbar = false">
                  <v-icon> {{ icons[m.type] }} </v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}
.flex h3 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
