import Vue from "vue";
import moment from "moment";
import Vuex from "vuex";
import VueJWT from "vuejs-jwt";
import eventBus from "../eventBus.js";
import {
  LOGIN_ENDPOINT,
  API_BASE,
  NEWS_ENDPOINT,
  // DEFAULT_HEADER,
  // JWT_DEFAULTS,
  // REPISTORY_ENDPOINT,
} from "../util/constants.js";
import createPersistedState from "vuex-persistedstate";
import {
  toQueryString,
  getLoggedInHeader,
  getMultipartHeader,
} from "../util/functions";

Vue.use(Vuex);
Vue.use(VueJWT, {
  keyName: "token",
  storage: "localStorage",
});
const ADMIN = "ROLE_ADMIN";
export default new Vuex.Store({
  state: {
    auth: {
      isLoggedIn: false,
      token: null,
      role: null,
    },
  },
  getters: {
    isTokenValid() {
      const token = localStorage.getItem("token");
      if (token) {
        const decoded = Vue.$jwt.decode(token);

        return moment.unix(decoded.exp).isAfter(moment());
      }
      return false;
    },
    isAdmin(state) {
      return state.auth.role === ADMIN;
    },
  },
  mutations: {
    SAVE_ROLE: (state, role) => {
      state.auth.role = role;
    },
    LOG_IN: (state, token) => {
      localStorage.setItem("token", token);
      state.auth.isLoggedIn = true;
      state.auth.token = token;
      localStorage.setItem("hadLastRoute", "true");
      eventBus.$emit("AFTER_NAVIGATION");
    },
    LOG_OUT: (state) => {
      state.auth.isLoggedIn = false;
      state.auth.token = "";
      localStorage.removeItem("token");
      // localStorage.removeItem("hadLastRoute");
      // localStorage.removeItem("vuex");
    },
  },
  actions: {
    async logIn({ commit }, user) {
      let url = `${API_BASE}${LOGIN_ENDPOINT}`;

      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          commit("LOG_IN", jsonResponse.jwtToken);
          commit("SAVE_ROLE", jsonResponse.roles[0]);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async postData({ commit }, data) {
      let query = toQueryString({ title: data.title, html: data.html });
      let url = `${API_BASE}${NEWS_ENDPOINT}?${query}`;
      let formData = new FormData();
      formData.append("newsImages", data.newsImages);
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: getLoggedInHeader(localStorage.getItem("token")),
          body: formData,
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async getCollection({ commit }, { end, payload }) {
      let url = `${API_BASE}${end}`;
      eventBus.$emit("LOAD_ON");
      const header = getLoggedInHeader(localStorage.getItem("token"));
      const query = {
        method: "GET",
        headers: header,
      };
      if (payload) {
        query.data = JSON.tringify(payload);
      }
      try {
        let response = await fetch(url, query);
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async getPatchCollection({ commit }, { end, payload }) {
      let url = `${API_BASE}${end}`;
      eventBus.$emit("LOAD_ON");
      const header = getLoggedInHeader(localStorage.getItem("token"));
      const query = {
        method: "PATCH",
        headers: header,
      };
      if (payload) {
        query.data = JSON.tringify(payload);
      }
      try {
        let response = await fetch(url, query);
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async removeItem({ commit }, { end, data }) {
      let url = `${API_BASE}${end}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));
      eventBus.$emit("LOAD_ON");
      try {
        let response = await fetch(url, {
          method: "DELETE",
          headers: header,
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async putPath({ commit }, { end, data }) {
      let url = `${API_BASE}${end}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));
      eventBus.$emit("LOAD_ON");

      const query = {
        method: "PUT",
        headers: header,
        redirect: "follow",
      };
      console.log(data);
      if (data) {
        query.data = JSON.stringify(data);
      }
      try {
        let response = await fetch(url, query);
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async addNewItem({ commit }, { end, data }) {
      let url = `${API_BASE}${end}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));

      if (end === "admin/admin-users") {
        header["Redirect-Uri"] = `${window.location.origin}/#/login`;
      }
      var form_data = new FormData();

      for (var key in data) {
        form_data.append(key, data[key]);
      }
      console.log(data);
      eventBus.$emit("LOAD_ON");
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: header,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const jsonResponse = await response.text();
          return response || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async importFromFile({ commit }, { end, data }) {
      let url = `${API_BASE}${end}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));
      var formdata = new FormData();

      formdata.append("stores", data, data.name);
      eventBus.$emit("LOAD_ON");
      var requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };

      try {
        let response = await fetch(url, requestOptions);
        if (response.ok) {
          const jsonResponse = await response.text();
          return response || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async getMedia({ commit }, { end, data }) {
      let query_string = toQueryString(data);
      let url = `${API_BASE}${end}?${query_string}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));

      var requestOptions = {
        method: "GET",
        headers: header,
        redirect: "follow",
      };

      try {
        let response = await fetch(url, requestOptions);
        if (response.ok) {
          const jsonResponse = await response.text();
          return response || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    purgeAll({ commit }) {
      commit("LOG_OUT");
      localStorage.removeItem("vuex");
      localStorage.removeItem("token");
      window.location.reload();
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
