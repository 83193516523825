// META TYPE MAPPINGS
export const FOLDER_TYPE = "Folder";

export const JWT_DEFAULTS = {
  signKey:
    "MIIBOgIBAAJAbvoCukIVuUBumb3i7kx9GbPCL4v/yqXrGuowGcrLYG02ANMLbIcv GeFxnnAhh0B1aDjJeL4AFDVD6wV+D1aehQIDAQABAkAcPyR8lh+DZIQCO9PMBcn9 xcRSZ9b2lJ6v3hXaKsTzSOLtpVqZjlZL8CkKnUSbuOd1kGMSi++l4Btp7OHl1jit AiEAtAGSWOUt32uFeukQ9NcRWqeH56HbOfS/Ck+MuVJcqq8CIQCd0/hs7q4LD7Jt is9yxvdU2TetwNzwa+xgKHFjsHeHCwIhAICpGuyVKrulw6t9iRQn4QyV8Er84pBD QoVhJKmVmAybAiEAm8ujnjxZ15duJY3xy7Y2bi5iNQV902JlmmIx1BtdUhMCIAG0 r6T3vI1gY0V5sjzZonzGCTUqBY+1JC++4LAzhuhN",
  keyName: "token",
  storage: "localStorage",
};

// API STUFF

// ANY .env trouble?
// https://cli.vuejs.org/guide/mode-and-env.html#modes
export const API_BASE = process.env.VUE_APP_API_BASE;
export const IMAGE_BASE = process.env.VUE_APP_API_BASE.slice(0, -1);
export const LOGIN_ENDPOINT = "admin/users/auth/login";
export const CLIENTS_ENDPOINT = "admin/client-users";
export const NEWS_ENDPOINT = "admin/news";
export const PWRESET_BASE = `${window.location.origin}/#/login`;
// Default Fetch Header pre-auth
export const DEFAULT_HEADER = { "Content-Type": "application/json" };
