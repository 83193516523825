<template>
  <v-tabs align-with-title v-model="active_tab">
    <v-tab v-for="item in items" :key="item.id" @click="handleClick(item)">
      {{ item.label }}
    </v-tab>
  </v-tabs>
</template>
<script>
import eventBus from "../eventBus.js";
export default {
  data() {
    return {
      items: [],
      active_tab: 0,
      defaultItems: [
        {
          label: "Bejelentkezés",
          slug: "login",
          disabled: false,
          action: null,
          id: 0,
        },
      ],
      loggedInItems: [
        {
          label: "Kereskedők",
          slug: "kereskedok",
          disabled: false,
          id: 0,
        },

        {
          label: "Feltöltések",
          slug: "feltoltesek",
          disabled: false,
          id: 1,
        },
        {
          label: "Admin Felhasználók",
          slug: "admin-felhasznalok",
          disabled: false,
          id: 2,
        },
        {
          label: "Értesítések",
          slug: "ertesitesek",
          disabled: false,
          id: 3,
        },
        {
          label: "Beállítások",
          slug: "beallitasok",
          disabled: false,
          id: 4,
        },
        {
          label: "Hírek",
          slug: "hirek",
          disabled: false,
          id: 5,
        },
        {
          label: "Termékek",
          slug: "termekek",
          disabled: false,
          id: 6,
        },
      ],
      supportItems: [
        {
          label: "Kereskedők",
          slug: "kereskedok",
          disabled: false,
          id: 0,
        },

        {
          label: "Feltöltések",
          slug: "feltoltesek",
          disabled: false,
          id: 1,
        },
      ],
    };
  },
  mounted() {
    // YIELD BY LOGGED IN STATE
    // this.items = Array.from(this.defaultItems);

    this.updateNavItems();
    eventBus.$on("AFTER_NAVIGATION", () => {
      this.updateNavItems();
    });
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    navigateTo(slug) {
      if (this.$route.name !== slug) {
        this.$router.push(slug);
        eventBus.$emit("AFTER_NAVIGATION");
      }
    },
    handleClick(item) {
      if (item.action) {
        this.$store.dispatch(item.action);
      } else {
        this.navigateTo(item.slug);
      }
    },
    updateActiveTab() {
      setTimeout(() => {
        if (this.$store.state.auth.isLoggedIn) {
          const item = this.items.find((o) => o.slug === this.$route.name);
          if (item) {
            this.active_tab = item.id;
          }
          // console.log(item);
        }
      }, 100);
    },
    updateNavItems() {
      if (!this.$store.getters.isTokenValid) {
        this.items = this.defaultItems;
      } else {
        if (this.isAdmin) {
          this.items = this.loggedInItems;
        } else {
          this.items = this.supportItems;
        }
      }
      this.updateActiveTab();
    },
  },
};
</script>
