<template>
  <v-app id="main">
    <!-- <Debug></Debug> -->
    <!-- <v-navigation-drawer v-model="drawer" app> </v-navigation-drawer> -->
    <v-progress-linear
      class="abs"
      indeterminate
      color="red"
      v-if="isLoading"
    ></v-progress-linear>
    <v-app-bar app>
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
      <img class="logo" src="./assets/hell_app_logo.png" alt="" />
      <v-toolbar-title>{{ $t("app.title") }}</v-toolbar-title>
      <Navigation v-if="isVisible"></Navigation>
      <v-btn elevation="0" @click="handleLogout()" v-if="isVisible">
        <v-icon>mdi-exit-to-app</v-icon>
        {{ $t("navigation.logout") }}
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <MessageBar></MessageBar>
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import "firebase/app";
import "firebase/messaging";

import MessageBar from "./components/MessageBar.vue";
import Navigation from "./components/Navigation.vue";
// import Debug from "./components/Debug.vue";
import eventBus from "./eventBus.js";

export default {
  components: {
    MessageBar,
    Navigation,
    // Debug,
  },
  name: "App",
  data: () => ({
    drawer: false,
    isLoading: false,
  }),
  created() {
    eventBus.$on("LOAD_ON", () => {
      this.isLoading = true;
    });
    eventBus.$on("LOAD_OFF", () => {
      this.isLoading = false;
      //
    });
  },
  destroyed() {
    eventBus.$off("LOAD_ON");
    eventBus.$off("LOAD_OFF");
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("purgeAll");
    },
  },
  computed: {
    isVisible() {
      return this.$store.getters.isTokenValid;
    },
  },
  mounted() {
    // var config = {
    //   apiKey: "AIzaSyCysgLrYgewjvwonrspr2xP0e3hd6pFPMA",
    //   authDomain: "hell-energy-5eb96.firebaseapp.com",
    //   databaseURL: "https://hell-energy-5eb96.firebaseio.com",
    //   projectId: "hell-energy-5eb96",
    //   storageBucket: "hell-energy-5eb96.appspot.com",
    //   appId: "1:494277965836:web:9c7441903bebad559a1629",
    //   messagingSenderId: "494277965836",
    // };
    // firebase.initializeApp(config);
    // const messaging = firebase.messaging();
    // messaging.usePublicVapidKey(
    //   "BIMy_e-lKQIH3a0q8smXSZXvl5SmV-F0ulEFR3VJYRb8l02NHTyKp2YiOlC8xFhYmiFv7EDij857jXoiJofLfMI"
    // );
    // messaging
    //   .requestPermission()
    //   .then(() => {
    //     console.log("Notification permission granted.");
    //     messaging.getToken().then((token) => {
    //       console.log("New token created: ", token);
    //       localStorage.setItem("pushToken", token);
    //     });
    //   })
    //   .catch((err) => {
    //     console.log("Unable to get permission to notify.", err);
    //   });
    // messaging.onTokenRefresh(function () {
    //   messaging
    //     .getToken()
    //     .then(function (newToken) {
    //       console.log("Token refreshed: ", newToken);
    //       localStorage.setItem("pushToken", newToken);
    //     })
    //     .catch(function (err) {
    //       console.log("Unable to retrieve refreshed token ", err);
    //     });
    // });
  },
};
</script>

<style scoped>
.abs {
  position: absolute;
  top: 0;
  z-index: 100;
}
.v-toolbar__title {
  color: var(--red);
  font-weight: 700;
  padding-left: 20px;
  margin-left: 20px;
  min-width: 120px;
  border-left: 3px solid;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
.logo {
  width: 60px;
}
</style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
$font-family: "Rubik";
.v-application {
  [class*="text-"] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
* {
  font-family: Rubik;
}
.container {
  max-width: 1200px !important;
}
.v-toolbar__title {
  padding-right: 1rem;
}
.v-main__wrap {
  background-color: #f2f2f2;
}
.bigDigit {
  font-size: 300%;
  display: block;
  font-weight: 100;
}
.spacer {
  padding: 1rem;
}
.bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:root {
  --red: #ed1c24;
  --secondary: #000;
}
.content {
  margin-top: 4rem;
}
.rscope {
  position: relative;
}
</style>
